import {
    ALL_COMMANDS,
    FADE_OUT_VARS,
    MUSIC_BED_AUDIO_FILE_NAME_PREFIX,
    PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX,
    SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX,
} from './audioContextConstants';
import {
    generateAlgorithmCommands,
    masterButtonColorMap,
    masterButtonIconMap,
    masterButtonImageSourceMap,
    masterButtonSubtitleMap,
    masterButtonTitleMap,
} from './audioContextHelpers';
import MusicBedAudioNode from './audioNodes/MusicBedAudioNode';
import NextElementAudioNode from './audioNodes/NextElementAudioNode';
import PreProducedAudioNode from './audioNodes/PreProducedAudioNode';
import PreviousElementAudioNode from './audioNodes/PreviousElementAudioNode';
import RecordingNode from './audioNodes/RecordingNode';
import SoundEffectAudioNode from './audioNodes/SoundEffectAudioNode';
import SkippedDuration from './SkippedDuration';
import {RecordingAlgorithmActions} from './store/recording-algorithm.action';
import UpdateSubscriptions from './UpdateSubscriptions';
import {SUBSCRIPTION_TYPES} from '../../constants';
import {Debug} from '../../lib/debug';
import {IndexedDb} from '../../lib/indexed-db';
import {toastInstance} from '../../lib/toast';
import {CurrentTaskActions} from '../current-task';
import {LOADING_TYPES, LoadingActions} from '../loading';
import {SoundSettings} from '../sound-settings/api/sound-settings.dto';

const UI_UPDATE_INTERVAL = 1000 / 30;

/**
 * RecordingAlgorithm knows how to answer on each of user's instructions while in connection with real AudioContext.
 */
class RecordingAlgorithm extends UpdateSubscriptions {
    constructor() {
        super();

        this.#setupListeners();
    }

    /**
     * @type {AudioContext|null}
     * @private
     */
    #audioContext = null;

    #reduxDispatch = null;

    /**
     * @type {{
     *     previousElementNode: PreviousElementAudioNode,
     *     nextElementNode: BaseAudioNode,
     *     recordingNode: RecordingNode,
     * }}
     * @private
     */
    #nodes = {
        previousElementNode: null,
        nextElementNode: null,
        recordingNode: null,
    };

    /**
     * Tracks the index of the last executed command.
     *
     * @type {number}
     * @private
     */
    #currentCommandIndex = -1;

    /**
     * Tracks the index of the recorded stream where we need to save the mic stream.
     *
     * @type {number}
     */
    #currentRecordedStreamIndex = -1;

    /**
     * Tracks the index of current pre produced audio.
     *
     * @type {number}
     * @private
     */
    #currentPreProducedAudioIndex = 0;

    /**
     * Array of recorded streams.
     *
     * @type {Array}
     * @private
     */
    #recordedStreams = [];

    /**
     * The length of the whole moderation (without previous and next elements).
     * Precision is defined as UI_UPDATE_INTERVAL.
     *
     * @type {number}
     * @private
     */
    #moderationDuration = 0;

    /**
     * @type {array}
     * @private
     */
    #commands = [
        ALL_COMMANDS.INIT,
        ALL_COMMANDS.PLAY_PREVIOUS_ELEMENT,
    ];

    /**
     * @type {boolean}
     * @private
     */
    #isPlayingPreProducedAudio = false;

    /**
     * @type {boolean}
     * @private
     */
    #isPlayingSoundEffect = false;

    /**
     * @type {boolean}
     * @private
     */
    #isPlayingMusicBed = false;

    /**
     * @type {boolean}
     * @private
     */
    #canPlayPreProducedAudio = false;

    /**
     * @type {boolean}
     * @private
     */
    #canPlaySoundEffect = false;

    /**
     * @type {boolean}
     * @private
     */
    #canPlayMusicBed = false;

    /**
     * @type {boolean}
     * @private
     */
    #canExecuteNextCommand = true;

    /**
     * @type {boolean}
     * @private
     */
    #shouldCountPassedTimeOfModeration = false;

    /**
     * @type {boolean}
     * @private
     */
    #canPreviousElementBeStopped = true;

    /**
     * @type {{
     *  forbidSkipPreProducedAudioTimeout: number,
     *  onPreviousElementEndTimeout: number,
     *  onNextElementEndTimeout: number,
     *  fadeOutNextElementTimeout: number,
     *  moderationDurationInterval: number,
     *  onPreProducedAudioEndTimeout: number,
     *  onMusicBedEndTimeout: number,
     *  algorithmEndInterval: number,
     *  continuePlayingPreviousElementTimeout: number
     * }}
     * @private
     */
    #timeoutsAndIntervals = {
        onPreProducedAudioEndTimeout: 0,
        forbidSkipPreProducedAudioTimeout: 0,
        fadeOutNextElementTimeout: 0,
        onPreviousElementEndTimeout: 0,
        onNextElementEndTimeout: 0,
        moderationDurationInterval: 0,
        onMusicBedEndTimeout: 0,
        algorithmEndInterval: 0,
        continuePlayingPreviousElementTimeout: 0,
        fadeInPreviousElement: 0,
    };

    /**
     * @type {number}
     * @private
     */
    #preProducedAudiosVolume = 1.0;

    /**
     * @type {number}
     * @private
     */
    #soundEffectAudiosVolume = 1.0;

    /**
     * @type {number}
     * @private
     */
    #musicBedAudiosVolume = 1.0;

    /**
     * @type {SoundSettings}
     * @private
     */
    #soundSettings = new SoundSettings();

    /**
     * @type {boolean}
     * @private
     */
    #isDone = false;

    /**
     * @type {boolean}
     * @private
     */
    #isUploaded = false;

    /**
     * Total duration of recording.
     *
     * @type {number}
     * @private
     */
    #playbackDuration = 0;

    /**
     * @type {number}
     * @private
     */
    #algorithmStartedAt = 0;

    /**
     * @type {number}
     * @private
     */
    #requestAnimationFrameCallback;

    /**
     * @type {number}
     * @private
     */
    #requestAnimationFrameTimestamp = 0;

    /**
     * @type {number}
     * @private
     */
    #totalSkippedDuration = 0;

    /**
     * @type {HTMLAudioElement}
     * @private
     */
    #audio;

    /**
     * @type {MediaStreamAudioDestinationNode}
     * @private
     */
    #destination;

    #allowKeyboardEvents = true;

    /**
     * @type {boolean}
     */
    #canBePlayed = false;

    #endSagaPromise = null;

    /**
     * Prepares the command execution.
     *
     * @param command
     */
    #commandExecutor = async command => {
        this.#incrementCurrentCommandIndex();

        if (!command) {
            command = this.#commands[this.#currentCommandIndex];
        }

        if (Array.isArray(command)) {
            // Execute multiple commands
            // command.forEach(command => {
            //     Debug.debug('RecordingAlgorithm', `Executing command: ${command}.`);
            //
            //     this.#executeCommand(command);
            // });
            await Promise.all(command.map(command => this.#executeCommand(command)));

            this.notify([SUBSCRIPTION_TYPES.DISABLE_RECORDING_CONTROLS]);

            return;
        }

        Debug.debug('RecordingAlgorithm', `Executing command: ${command}.`);

        await this.#executeCommand(command);

        this.notify([SUBSCRIPTION_TYPES.DISABLE_RECORDING_CONTROLS]);
    };

    setOutputDevice = deviceId => {
        if (!this.#audioContext) {
            return;
        }

        let contextOrAudio = this.#audioContext;

        if (!this.#audioContext.setSinkId) {
            contextOrAudio = this.#audio;
        }

        contextOrAudio.setSinkId(deviceId)
            .then(() => {
                // eslint-disable-next-line no-console
                console.warn(`Success, audio output device attached: ${deviceId}`);
            })
            .catch(error => {
                let errorMessage = error;

                if (error.name === 'SecurityError') {
                    errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                }

                // eslint-disable-next-line no-console
                console.error(errorMessage);
            });
    };

    /**
     * Executes the desired command.
     *
     * @param command
     */
    #executeCommand = async command => {
        switch (command) {
            case ALL_COMMANDS.INIT:
                await this.#initCommandFlow();

                this.#canPlaySoundEffect = true;
                this.#canPlayMusicBed = true;

                break;

            case ALL_COMMANDS.PLAY_PREVIOUS_ELEMENT:
                this.#playPreviousElementCommandFlow();

                break;

            case ALL_COMMANDS.DUCK_PREVIOUS_ELEMENT:
                this.#duckPreviousElementCommandFlow();

                break;

            case ALL_COMMANDS.STOP_PREVIOUS_ELEMENT:
                this.#stopBumperFlow();

                break;

            case ALL_COMMANDS.START_RECORDING:
                this.#startRecordingFlow();

                break;

            case ALL_COMMANDS.STOP_RECORDING:
                await this.#stopRecordingFlow();

                break;

            case ALL_COMMANDS.PLAY_PRE_PRODUCED_AUDIO:
                this.#playPreProducedAudioFlow();

                break;

            case ALL_COMMANDS.SKIP_PRE_PRODUCED_AUDIO:
                this.#skipPreProducedAudioFlow();

                break;

            case ALL_COMMANDS.PLAY_NEXT_ELEMENT:
                this.#playNextElementFlow();

                break;

            case ALL_COMMANDS.FADE_IN_NEXT_ELEMENT:
                this.#fadeInNextElement();

                break;

            case ALL_COMMANDS.STOP_NEXT_ELEMENT:
                this.#stopNextElementFlow();

                break;

            default:
                throw new Error('Unsupported command.');
        }
    };

    #updateStep = 1000 / 60;

    #onAnimationFrameCallback = timestamp => {
        if ((timestamp - this.#requestAnimationFrameTimestamp) < this.#updateStep) {
            this.#requestAnimationFrameCallback = window.requestAnimationFrame(this.#onAnimationFrameCallback);
            return;
        }

        this.#requestAnimationFrameTimestamp = timestamp;

        const types = [
            SUBSCRIPTION_TYPES.MASTER_BUTTON,
            SUBSCRIPTION_TYPES.MODERATION_DURATION,
        ];

        if (this.#nodes.previousElementNode) {
            types.push(SUBSCRIPTION_TYPES.PREVIOUS_ELEMENT_DATA);
        }

        if (this.#nodes.nextElementNode) {
            types.push(SUBSCRIPTION_TYPES.NEXT_ELEMENT_DATA);
        }

        if (this.#isPlayingPreProducedAudio) {
            types.push(SUBSCRIPTION_TYPES.PRE_PRODUCED_AUDIO_DATA);
        }

        if (this.#isPlayingSoundEffect) {
            types.push(SUBSCRIPTION_TYPES.SOUND_EFFECT_DATA);
        }

        if (this.#isPlayingMusicBed) {
            types.push(SUBSCRIPTION_TYPES.MUSIC_BED_DATA);
        }

        this.notify(types);

        this.#requestAnimationFrameCallback = window.requestAnimationFrame(this.#onAnimationFrameCallback);
    };

    #initCommandFlow = async () => {
        this.#canExecuteNextCommand = false;

        this.notify([SUBSCRIPTION_TYPES.MASTER_BUTTON]);

        const inputDeviceId = localStorage.getItem('inputDeviceId');
        const outputDeviceId = localStorage.getItem('outputDeviceId');

        window.navigator.mediaDevices
            .getUserMedia({
                audio: {
                    autoGainControl: false,
                    echoCancellation: false,
                    noiseSuppression: false,
                    deviceId: {
                        exact: inputDeviceId || undefined,
                    },
                },
            })
            .then(async stream => {
                this.#audioContext = new window.AudioContext({
                    latencyHint: 'interactive',
                });

                await this.#audioContext.suspend();

                if (!this.#audioContext.setSinkId) {
                    this.#audio = new Audio();

                    this.#destination = new MediaStreamAudioDestinationNode(this.#audioContext);

                    this.#audio.srcObject = this.#destination.stream;

                    await this.#audio.play();
                }

                if (outputDeviceId) {
                    this.setOutputDevice(outputDeviceId);
                }

                await this.#setupRecordingNode(stream);

                this.#canExecuteNextCommand = false;

                this.#requestAnimationFrameCallback = window.requestAnimationFrame(this.#onAnimationFrameCallback);

                // Start measuring moderation duration.
                this.#timeoutsAndIntervals.moderationDurationInterval = setInterval(() => {
                    if (this.#shouldCountPassedTimeOfModeration) {
                        this.#moderationDuration += UI_UPDATE_INTERVAL;
                    }
                }, UI_UPDATE_INTERVAL);

                this.#reduxDispatch({
                    type: 'ALGORITHM_INITIALIZED',
                });
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.error({error});

                this.#decrementCurrentCommandIndex();

                let message = 'Oops, an unknown error occurred.';

                if (error && error.response && error.response.data && error.response.data.message) {
                    message = error.response.data.message;
                } else if (error && error.code === 8) {
                    message = 'featuresRecordingAlgorithm:missingDeviceError';
                }

                toastInstance.error(message);

                this.#reduxDispatch(LoadingActions.setIsLoading(LOADING_TYPES.COCKPIT_DATA, false));
                this.#reduxDispatch(RecordingAlgorithmActions.reset());
            });
    };

    #setupListeners = () => {
        document.addEventListener('keydown', this.#handleKeyDown);
    };

    #handleKeyDown = async event => {
        // If the body is not the activeElement, it means that the user focused something, a field or a button...
        // @see DDS-151 ticket
        // if (document.activeElement !== document.body) {
        //     return;
        // }

        if (document.activeElement.tagName === 'INPUT' || document.activeElement?.dataset?.lexicalEditor === 'true') {
            return;
        }

        if (!this.#allowKeyboardEvents) {
            return;
        }

        switch (event.code) {
            case 'NumpadAdd':
            case 'KeyZ':
                if (event.ctrlKey) {
                    return;
                }

                if (!this.getCanExecuteNextCommand()) {
                    return;
                }

                // TODO: This should be done somewhere in React. Or not?
                this.#reduxDispatch(RecordingAlgorithmActions.executeNextCommand());

                break;

            case 'Backspace':
                this.#reduxDispatch(RecordingAlgorithmActions.reset());

                break;

            case 'Numpad1':
                this.#reduxDispatch(CurrentTaskActions.setBedVolume(1, 'user'));

                break;

            case 'Numpad2':
                this.#reduxDispatch(CurrentTaskActions.setBedVolume(2, 'user'));

                break;

            case 'Numpad3':
                this.#reduxDispatch(CurrentTaskActions.setBedVolume(3, 'user'));

                break;

            case 'Numpad4':
                if (!this.#canPlayMusicBed) {
                    return;
                }

                if (this.isReadyToStart()) {
                    this.startTaskAndScheduleBed(0);

                    return;
                }

                this.playMusicBed(0, () => {
                    // this.#reduxDispatch(CurrentTaskActions.setBedVolume(2));
                });

                break;

            case 'Numpad5':
                if (!this.#canPlayMusicBed) {
                    return;
                }

                if (this.isReadyToStart()) {
                    this.startTaskAndScheduleBed(1);

                    return;
                }

                this.playMusicBed(1, () => {
                    // this.#reduxDispatch(CurrentTaskActions.setBedVolume(2));
                });

                break;

            case 'Numpad6':
                if (!this.#canPlayMusicBed) {
                    return;
                }

                if (this.isReadyToStart()) {
                    this.startTaskAndScheduleBed(2);

                    return;
                }

                this.playMusicBed(2, () => {
                    // this.#reduxDispatch(CurrentTaskActions.setBedVolume(2));
                });

                break;

            case 'Numpad7':
                if (!this.#canPlaySoundEffect) {
                    return;
                }

                if (this.isReadyToStart()) {
                    this.startTaskAndScheduleSfx(0);

                    return;
                }

                this.playSoundEffect(0);

                break;

            case 'Numpad8':
                if (!this.#canPlaySoundEffect) {
                    return;
                }

                if (this.isReadyToStart()) {
                    this.startTaskAndScheduleSfx(1);

                    return;
                }

                this.playSoundEffect(1);

                break;

            case 'Numpad9':
                if (!this.#canPlaySoundEffect) {
                    return;
                }

                if (this.isReadyToStart()) {
                    this.startTaskAndScheduleSfx(2);

                    return;
                }

                this.playSoundEffect(2);

                break;

            default:
                break;
        }
    };

    /**
     * Plays the bumper.
     */
    #playPreviousElementCommandFlow = () => {
        Debug.debug('RecordingAlgorithm', `Setting previous element volume to ${this.#soundSettings.previousElement.startVolume}.`);

        this.getPreviousElementNode().volume = this.#soundSettings.previousElement.startVolume;

        Debug.debug('RecordingAlgorithm', `Playing previous element.`);

        this.getPreviousElementNode().play();

        this.#algorithmStartedAt = this.#audioContext.currentTime;

        const duration = this.getPreviousElementNode().duration * 1000;
        let offset = duration - this.getPreviousElementNode().offset * 1000;

        Debug.debug('RecordingAlgorithm', `Scheduling end of previous element in ${offset} milliseconds.`);

        if (this.getPreviousElementNode()?.audioObject?.contentType === 'Music') {
            const cueOut = this.getPreviousElementNode().audioObject?.cueOut || 0;
            const fadeOutDuration = this.getPreviousElementNode().audioObject?.fadeOut || 0;
            const startNext = this.getPreviousElementNode().audioObject?.startNext || 0;
            offset = cueOut - startNext + 3000;
            const fadeOutStartIn = (cueOut - startNext - fadeOutDuration) + 3000;

            Debug.debug('RecordingAlgorithm', `Scheduling fade out start in ${fadeOutStartIn} milliseconds.`);

            this.#timeoutsAndIntervals.fadeInPreviousElement = setTimeout(() => {
                this.getPreviousElementNode().volumeTransition(
                    fadeOutDuration / 1000,
                    FADE_OUT_VARS.FADE_OUT_END_VOLUME,
                    true,
                );

                Debug.debug('RecordingAlgorithm', `Starting fade out of previous element for ${fadeOutDuration} milliseconds.`);
            }, fadeOutStartIn);
        }

        Debug.debug('RecordingAlgorithm', `Scheduling stop of previous element in ${offset} milliseconds.`);

        this.#timeoutsAndIntervals.onPreviousElementEndTimeout = setTimeout(() => {
            this.#canPreviousElementBeStopped = false;

            Debug.debug('RecordingAlgorithm', `Removing previous element command from the command queue.`);

            // Find stop bumper command and remove it from commands
            this.#commands = this.#commands.filter(command => command !== ALL_COMMANDS.STOP_PREVIOUS_ELEMENT);

            // Debug.debug('RecordingAlgorithm', `Stopping previous element.`);
            //
            // this.getPreviousElementNode().stop();
        }, offset);
    };

    /**
     * Ducks the bumper to the desired value.
     */
    #duckPreviousElementCommandFlow = () => {
        const duration = this.#soundSettings.previousElement.duckDuration;
        let toVolume = this.#soundSettings.previousElement.duckVolume;

        if (this.#nodes.previousElementNode.audioObject?.contentType === 'Music') {
            toVolume = 0.4;
        }

        Debug.debug('RecordingAlgorithm', `Ducking previous element to volume ${toVolume} over duration of ${duration} seconds.`);

        this.#nodes.previousElementNode.volumeTransition(duration, toVolume);
    };

    /**
     * Starts the recording process. Creates new recording.
     */
    #startRecordingFlow = () => {
        if (!this.#algorithmStartedAt) {
            this.#algorithmStartedAt = this.#audioContext.currentTime;
        }

        // Create new recording
        this.#createNewRecording();

        if (this.#isPlayingPreProducedAudio) {
            this.#canExecuteNextCommand = false;
        }

        if (this.#currentRecordedStreamIndex === 0) {
            this.#shouldCountPassedTimeOfModeration = true;

            this.#canPlayPreProducedAudio = true;
            this.#canPlaySoundEffect = true;
            this.#canPlayMusicBed = true;
        }

        this.#nodes.recordingNode.start();

        this.notify([SUBSCRIPTION_TYPES.IS_RECORDING]);

        Debug.debug('RecordingAlgorithm', `Recording started.`);
    };

    /**
     * Stops the recording. Stops all MediaStreamTracks. Removes audioprocess event listener.
     */
    #stopRecordingFlow = async () => {
        Debug.debug('RecordingAlgorithm', `Current recording stopped.`);

        this.#nodes.recordingNode.stopCurrentRecording();

        this.notify([SUBSCRIPTION_TYPES.IS_RECORDING]);

        // This code will be executed when the last recording stops
        if (this.#commands[this.#currentCommandIndex + 1] === ALL_COMMANDS.UPLOAD) {
            this.#allowKeyboardEvents = false;

            Debug.debug('RecordingAlgorithm', `Last recording stopped, clean up.`);

            Debug.debug('RecordingAlgorithm', `Stop counting moderation duration.`);

            this.#shouldCountPassedTimeOfModeration = false;

            Debug.debug('RecordingAlgorithm', `Forbid execution of the next command.`);

            this.#canExecuteNextCommand = false;

            this.notify([SUBSCRIPTION_TYPES.DISABLE_RECORDING_CONTROLS]);

            Debug.debug('RecordingAlgorithm', `Completely stop the recording node.`);

            await this.#nodes.recordingNode.stop();

            Debug.debug('RecordingAlgorithm', `Forbid playing of audio elements.`);

            if (this.#nodes.previousElementNode && this.#nodes.previousElementNode.isPlaying) {
                this.#nodes.previousElementNode.stop();

                this.notify([SUBSCRIPTION_TYPES.PREVIOUS_ELEMENT_DATA]);
            }

            const currentlyPlayingPreProducedAudio = Object.keys(this.#nodes)
                .filter(nodeKey => nodeKey.includes(PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX))
                .map(nodeKey => this.#nodes[nodeKey])
                .find(node => node.isPlaying);

            const currentlyPlayingSoundEffect = Object.keys(this.#nodes)
                .filter(nodeKey => nodeKey.includes(SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX))
                .map(nodeKey => this.#nodes[nodeKey])
                .find(node => node.isPlaying);

            const currentlyPlayingMusicBed = Object.keys(this.#nodes)
                .filter(nodeKey => nodeKey.includes(MUSIC_BED_AUDIO_FILE_NAME_PREFIX))
                .map(nodeKey => this.#nodes[nodeKey])
                .find(node => node.isPlaying);

            if (currentlyPlayingPreProducedAudio || currentlyPlayingSoundEffect || currentlyPlayingMusicBed) {
                if (currentlyPlayingPreProducedAudio) {
                    const duration = this.#soundSettings.preProduced.fadeOutOnSkipDuration;
                    const toVolume = FADE_OUT_VARS.FADE_OUT_END_VOLUME;

                    Debug.debug('RecordingAlgorithm', `Found playing pre-produced element, scheduling fade out over ${duration} seconds.`);

                    currentlyPlayingPreProducedAudio.volumeTransition(duration, toVolume, true);

                    await new Promise(resolve => setTimeout(resolve, (duration * 1000) + 100));

                    this.notify([SUBSCRIPTION_TYPES.PRE_PRODUCED_AUDIO_DATA]);
                }

                if (currentlyPlayingSoundEffect) {
                    const duration = this.#soundSettings.sfx.fadeOutOnSkipDuration;
                    const toVolume = FADE_OUT_VARS.FADE_OUT_END_VOLUME;

                    Debug.debug('RecordingAlgorithm', `Found playing sound effect, scheduling fade out over ${duration} seconds.`);

                    currentlyPlayingSoundEffect.volumeTransition(duration, toVolume, true);

                    await new Promise(resolve => setTimeout(resolve, (duration * 1000) + 100));

                    this.notify([SUBSCRIPTION_TYPES.SOUND_EFFECT_DATA]);
                }

                if (currentlyPlayingMusicBed) {
                    const duration = this.#soundSettings.bed.fadeOutOnSkipDuration;
                    const toVolume = FADE_OUT_VARS.FADE_OUT_END_VOLUME;

                    Debug.debug('RecordingAlgorithm', `Found playing music bed, scheduling fade out over ${duration} seconds.`);

                    currentlyPlayingMusicBed.volumeTransition(duration, toVolume, true);

                    await new Promise(resolve => setTimeout(resolve, (duration * 1000) + 100));

                    this.notify([SUBSCRIPTION_TYPES.MUSIC_BED_DATA]);
                }
            }

            if (!this.#nodes.nextElementNode) {
                Debug.debug('RecordingAlgorithm', `There is no next element, finalizing algorithm immediately.`);

                await this.#finalizeAlgorithm().then(() => {
                    clearInterval(this.#timeoutsAndIntervals.moderationDurationInterval);
                    cancelAnimationFrame(this.#requestAnimationFrameCallback);

                    Debug.debug('RecordingAlgorithm', `Algorithm ended.`);
                });

                return;
            }

            Debug.debug('RecordingAlgorithm', `Calculating playing time of next element...`);

            const {introLength} = this.#nodes.nextElementNode.audioObject;

            let elementDuration = this.#nodes.nextElementNode.duration;

            if (introLength) {
                elementDuration = (introLength + 3000) / 1000;
            }

            const passedTime = this.getNextElementNode().getPassedTime();
            const countdownDirection = this.getNextElementNode().getCountdownDirection();

            let timeoutDuration = (passedTime - elementDuration) * 1000;

            if (countdownDirection === 'negative') {
                timeoutDuration = (elementDuration - passedTime) * 1000;
            }

            Debug.debug('RecordingAlgorithm', `The next element will be played for another ${timeoutDuration / 1000} seconds.`);

            if (!this.#nodes.nextElementNode.stoppedAt.length) {
                clearTimeout(this.#timeoutsAndIntervals.onNextElementEndTimeout);

                Debug.debug('RecordingAlgorithm', `The next element will be stopped in ${timeoutDuration} seconds.`);

                this.#timeoutsAndIntervals.onNextElementEndTimeout = setTimeout(() => {
                    this.#stopNextElementFlow();
                }, timeoutDuration / 1000);

                return;
            }

            Debug.debug('RecordingAlgorithm', `The next element is already stopped, finalizing algorithm immediately.`);

            if (this.#nodes.nextElementNode.stoppedAt.length) {
                await this.#finalizeAlgorithm().then(() => {
                    clearInterval(this.#timeoutsAndIntervals.moderationDurationInterval);
                    cancelAnimationFrame(this.#requestAnimationFrameCallback);

                    Debug.debug('RecordingAlgorithm', `Algorithm ended.`);
                });
            }
        }
    };

    /**
     * Stops the recording. Stops all MediaStreamTracks. Removes audioprocess event listener.
     */
    #stopBumperFlow = () => {
        if (this.#timeoutsAndIntervals.onPreviousElementEndTimeout) {
            clearTimeout(this.#timeoutsAndIntervals.onPreviousElementEndTimeout);

            this.#canPreviousElementBeStopped = false;
        }

        const duration = this.#soundSettings.previousElement.fadeOutOnSkipDuration;
        const toVolume = FADE_OUT_VARS.FADE_OUT_END_VOLUME;

        Debug.debug('RecordingAlgorithm', `Fading out previous element over ${duration} seconds.`);

        this.#nodes.previousElementNode.volumeTransition(duration, toVolume, true);
    };

    /**
     * Stops the recording. Stops all MediaStreamTracks. Removes audioprocess event listener.
     */
    #playNextElementFlow = () => {
        Debug.debug('RecordingAlgorithm', `Playing next element at volume ${this.#soundSettings.nextElement.startVolume}.`);

        // Find any music bed nodes which are currently playing and fade them out
        const currentlyPlayingMusicBed = Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(MUSIC_BED_AUDIO_FILE_NAME_PREFIX))
            .map(nodeKey => this.#nodes[nodeKey])
            .find(node => node.isPlaying);

        if (currentlyPlayingMusicBed) {
            const duration = this.#soundSettings.bed.previousElementFadeOutDuration;
            const toVolume = FADE_OUT_VARS.FADE_OUT_END_VOLUME;

            Debug.debug('RecordingAlgorithm', `Found playing music bed, stopping over ${duration} seconds.`);

            currentlyPlayingMusicBed.volumeTransition(duration, toVolume, true);
        }

        Debug.debug('RecordingAlgorithm', `Setting volume of next element to ${this.#soundSettings.nextElement.startVolume}.`);

        this.#nodes.nextElementNode.volume = this.#soundSettings.nextElement.startVolume;

        Debug.debug('RecordingAlgorithm', `Playing next element.`);

        this.#nodes.nextElementNode.play();

        Debug.debug('RecordingAlgorithm', `Calculating next element playing time left...`);

        const {introLength} = this.#nodes.nextElementNode.audioObject;

        let timeoutDuration = this.#nodes.nextElementNode.duration * 1000;

        if (introLength) {
            timeoutDuration = introLength + 3000;
        }

        Debug.debug('RecordingAlgorithm', `Playing time left of next element is ${timeoutDuration / 1000} seconds. Scheduling stop...`);

        this.#timeoutsAndIntervals.onNextElementEndTimeout = setTimeout(async () => {
            Debug.debug('RecordingAlgorithm', `Stopping next element.`);

            await this.#onNextElementEnd();
        }, timeoutDuration);

        Debug.debug('RecordingAlgorithm', `Scheduling fade out of next element in ${timeoutDuration / 1000 - this.#soundSettings.nextElement.fadeOutOnSkipDuration} seconds.`);

        // Schedule fade out of next element
        this.#timeoutsAndIntervals.fadeOutNextElementTimeout = setTimeout(async () => {
            const duration = this.#soundSettings.nextElement.fadeOutOnSkipDuration;
            const toVolume = FADE_OUT_VARS.FADE_OUT_END_VOLUME;

            Debug.debug('RecordingAlgorithm', `Fading out next element over ${duration} seconds.`);

            this.#nodes.nextElementNode.volumeTransition(duration, toVolume);
        }, timeoutDuration - this.#soundSettings.nextElement.fadeOutOnSkipDuration * 1000);
    };

    /**
     * Sets the volume of the next element to 100%.
     */
    #fadeInNextElement = () => {
        const duration = this.#soundSettings.nextElement.duckDuration;
        let toVolume = this.#soundSettings.nextElement.duckVolume;

        if (this.#nodes.nextElementNode.audioObject?.contentType === 'Music') {
            toVolume = 0.4;
        }

        Debug.debug('RecordingAlgorithm', `Fading in next element from volume ${this.#nodes.nextElementNode.volume} to volume ${toVolume} over ${duration} seconds.`);

        this.#nodes.nextElementNode.volumeTransition(duration, toVolume);

        Debug.debug('RecordingAlgorithm', `Stop counting moderation duration.`);

        this.#shouldCountPassedTimeOfModeration = false;
    };

    /**
     * Stops the recording. Stops all MediaStreamTracks. Removes audioprocess event listener.
     */
    #stopNextElementFlow = () => {
        if (this.#timeoutsAndIntervals.onNextElementEndTimeout) {
            clearTimeout(this.#timeoutsAndIntervals.onNextElementEndTimeout);

            Debug.debug('RecordingAlgorithm', `Cleared timeout for next element end.`);
        }

        if (this.#timeoutsAndIntervals.fadeOutNextElementTimeout) {
            clearTimeout(this.#timeoutsAndIntervals.fadeOutNextElementTimeout);

            Debug.debug('RecordingAlgorithm', `Cleared timeout for next element fade out.`);
        }

        const duration = this.#soundSettings.nextElement.fadeOutOnSkipDuration;
        const toVolume = FADE_OUT_VARS.FADE_OUT_END_VOLUME;

        Debug.debug('RecordingAlgorithm', `Fading out next element over ${duration} seconds.`);

        this.#nodes.nextElementNode.volumeTransition(duration, toVolume);

        this.#timeoutsAndIntervals.onNextElementEndTimeout = setTimeout(async () => {
            Debug.debug('RecordingAlgorithm', `Stopping next element.`);

            await this.#onNextElementEnd();
        }, this.#soundSettings.nextElement.fadeOutOnSkipDuration * 1000);
    };

    #playPreProducedAudioFlow = () => {
        if (!this.#canPlayPreProducedAudio) {
            Debug.debug('RecordingAlgorithm', `Can not play pre produced audio.`);

            return;
        }

        this.#isPlayingPreProducedAudio = true;

        const nodeKey = `${PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX}${this.#currentPreProducedAudioIndex}`;
        const audioObject = this.#nodes[nodeKey].audioObject;

        if (this.#nodes[nodeKey].duration < 5) {
            Debug.debug('RecordingAlgorithm', `Audio is shorter than 5 seconds, can not execute next command...`);

            this.#canExecuteNextCommand = false;
        } else if ((audioObject.cueIn + audioObject.startNext - 3000) > 0) {
            Debug.debug('RecordingAlgorithm', `CueIn (${audioObject.cueIn}) + StartNext (${audioObject.startNext}) - 3000 is bigger than 0, forbidding skip...`);

            this.#timeoutsAndIntervals.forbidSkipPreProducedAudioTimeout = setTimeout(() => {
                this.#incrementCurrentCommandIndex();

                this.#canExecuteNextCommand = false;
            }, audioObject.cueIn + audioObject.startNext - 3000);
        } else {
            Debug.debug('RecordingAlgorithm', `CueIn (${audioObject.cueIn}) + StartNext (${audioObject.startNext}) - 3000 was less than 0, forbidding skip based on duration...`);

            this.#timeoutsAndIntervals.forbidSkipPreProducedAudioTimeout = setTimeout(() => {
                this.#incrementCurrentCommandIndex();

                this.#canExecuteNextCommand = false;
            }, (this.#nodes[nodeKey].duration * 1000) - 3000);
        }

        this.#nodes[nodeKey].offset = audioObject.cueIn ? audioObject.cueIn / 1000 : 0;

        Debug.debug('RecordingAlgorithm', `Offset for pre produced audio is ${this.#nodes[nodeKey].offset} seconds.`);
        Debug.debug('RecordingAlgorithm', `Playing pre produced audio.`);

        this.#nodes[nodeKey].volume = this.#preProducedAudiosVolume;

        this.#nodes[nodeKey].play();

        Debug.debug('RecordingAlgorithm', `Scheduling stop of pre produced audio in ${this.#nodes[nodeKey].duration} seconds.`);

        this.#timeoutsAndIntervals.onPreProducedAudioEndTimeout = setTimeout(() => {
            Debug.debug('RecordingAlgorithm', `Stopping pre produced audio.`);

            this.#onPreProducedAudioEnd(nodeKey);
        }, this.#nodes[nodeKey].duration * 1000);
    };

    #skipPreProducedAudioFlow = () => {
        Debug.debug('RecordingAlgorithm', `Skipping pre produced audio.`);

        const nodeKey = `${PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX}${this.#currentPreProducedAudioIndex}`;

        this.#nodes[nodeKey].skip(this.#algorithmStartedAt);

        Debug.debug('RecordingAlgorithm', `Moderation duration increased for ${this.#nodes[nodeKey].getSkippedDuration()} seconds.`);

        // Get the skipped time and add it to the moderation duration
        this.#moderationDuration += this.#nodes[nodeKey].getSkippedDuration() * 1000;

        Debug.debug('RecordingAlgorithm', `Total skipped duration increased for ${this.#nodes[nodeKey].getSkippedDuration()} seconds.`);

        // Get the skipped time and add it to the moderation duration
        this.#totalSkippedDuration += this.#nodes[nodeKey].getSkippedDuration();

        Debug.debug('RecordingAlgorithm', `Extending duration of playing effects and beds for ${this.#nodes[nodeKey].getSkippedDuration()} seconds.`);

        this.#extendDurationsOfPlayingEffectsAndBeds(this.#nodes[nodeKey].getSkippedDuration());

        Debug.debug('RecordingAlgorithm', `Removing pre produced audio end and forbid skip timeouts.`);

        clearTimeout(this.#timeoutsAndIntervals.onPreProducedAudioEndTimeout);

        clearTimeout(this.#timeoutsAndIntervals.forbidSkipPreProducedAudioTimeout);

        Debug.debug('RecordingAlgorithm', `Rescheduling pre produced audio end timeout in ${this.#nodes[nodeKey].duration - this.#nodes[nodeKey].offset} seconds.`);

        this.#timeoutsAndIntervals.onPreProducedAudioEndTimeout = setTimeout(() => {
            Debug.debug('RecordingAlgorithm', `Stopping pre produced audio.`);

            this.#onPreProducedAudioEnd(nodeKey);
        }, this.#nodes[nodeKey].duration * 1000 - this.#nodes[nodeKey].offset * 1000);
    };

    #onPreProducedAudioEnd = nodeKey => {
        this.#isPlayingPreProducedAudio = false;

        // this.#canExecuteNextCommand = true;

        this.#nodes[nodeKey].stop(0);

        // this.#currentPreProducedAudioIndex += 1;

        this.notify([
            SUBSCRIPTION_TYPES.PRE_PRODUCED_AUDIO_DATA,
            SUBSCRIPTION_TYPES.MASTER_BUTTON,
            SUBSCRIPTION_TYPES.DISABLE_RECORDING_CONTROLS,
        ]);
    };

    /**
     * Creates new recording stream
     */
    #createNewRecording = () => {
        this.#currentRecordedStreamIndex += 1;

        this.#recordedStreams.push({
            bufferLength: 0,
            buffers: [
                [],
                [],
            ],
        });
    };

    /**
     * Sets up recording node and recording processor. Connects node to the processor and processor to the AudioContext.
     * Adds audioprocess event listener, so we can process the stream from the microphone.
     *
     * @param {MediaStream} stream
     */
    #setupRecordingNode = async stream => {
        this.#nodes.recordingNode = await new RecordingNode(this.#audioContext);
        await this.#nodes.recordingNode.initialize(stream, this.#handleAudioProcess);
    };

    /**
     * Main function for processing the microphone stream.
     * Doesn't process the stream if we're not recording.
     * Takes the stream from inputBuffer and saves it into the current recording stream in both channels.
     * Also, updates the buffer length of current recording stream.
     *
     * @param buffer
     */
    #handleAudioProcess = buffer => {
        this.#recordedStreams[this.#currentRecordedStreamIndex].buffers.push(buffer);
    };

    /**
     * Callback when next element ends playing.
     */
    #onNextElementEnd = async () => {
        this.#nodes.nextElementNode.stop();

        if (!this.#nodes.recordingNode.isRecording) {
            Debug.debug('RecordingAlgorithm', `Not recording anymore, finalize algorithm...`);

            await this.#finalizeAlgorithm();

            this.notify([SUBSCRIPTION_TYPES.NEXT_ELEMENT_DATA]);
        }
    };

    #incrementCurrentCommandIndex = () => {
        this.#currentCommandIndex += 1;

        this.notify([SUBSCRIPTION_TYPES.MASTER_BUTTON]);
    };

    #decrementCurrentCommandIndex = () => {
        this.#currentCommandIndex -= 1;

        this.notify([SUBSCRIPTION_TYPES.MASTER_BUTTON]);
    };

    #setCurrentCommandIndex = number => {
        this.#currentCommandIndex = number;

        this.notify([SUBSCRIPTION_TYPES.MASTER_BUTTON]);
    };

    /**
     * @param {number} duration
     */
    #extendDurationsOfPlayingEffectsAndBeds = duration => {
        this.getSoundEffectAudioNodes()
            .filter(node => node.isPlaying)
            .forEach(node => (node.addSkippedDuration(duration, SkippedDuration.TYPES.EXTEND_DURATION)));

        this.getMusicBedAudioNodes()
            .filter(node => node.isPlaying)
            .forEach(node => (node.addSkippedDuration(duration, SkippedDuration.TYPES.EXTEND_DURATION)));
    };

    resolveEndSagaPromise = () => {
        if (!this.#endSagaPromise) {
            return;
        }

        this.#endSagaPromise();
    };

    #finalizeAlgorithm = async () => {
        await this.#audioContext.close();

        this.#playbackDuration = this.#audioContext.currentTime;

        this.notify([SUBSCRIPTION_TYPES.RECORDING_ALGORITHM_DONE]);

        await new Promise(resolve => {
            this.#endSagaPromise = resolve;

            this.#reduxDispatch(RecordingAlgorithmActions.end());
        });

        await this.#endSagaPromise;

        this.#endSagaPromise = null;

        this.#canExecuteNextCommand = true;
        this.#canBePlayed = true;
        this.#allowKeyboardEvents = true;

        this.#isDone = true;

        this.notify([
            SUBSCRIPTION_TYPES.DISABLE_RECORDING_CONTROLS,
            SUBSCRIPTION_TYPES.MASTER_BUTTON,
            SUBSCRIPTION_TYPES.IS_RECORDING,
            SUBSCRIPTION_TYPES.RECORDING_ALGORITHM_DONE,
            SUBSCRIPTION_TYPES.CAN_BE_PLAYED,
            SUBSCRIPTION_TYPES.IS_PLAYING,
        ]);

        setTimeout(() => {
            window.cancelAnimationFrame(this.#requestAnimationFrameCallback);
        }, 1000);
    };

    /**
     * Just call the command executor.
     */
    async executeNextCommand() {
        const canExecuteNextCommand = this.#canExecuteNextCommand;
        this.#canExecuteNextCommand = false;

        await this.#commandExecutor();

        this.#canExecuteNextCommand = canExecuteNextCommand;
    }

    scheduleSfx = index => {
        const nodeKey = Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX))
            .find(nodeKey => nodeKey === `${SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX}${index}`);

        if (!nodeKey) {
            return;
        }

        const node = this.#nodes[nodeKey];

        const previousElementOffset = this.getPreviousElementNode().getDuration();

        Debug.debug('RecordingAlgorithm', `Scheduling play for sound effect in ${previousElementOffset} seconds.`);

        setTimeout(async () => {
            if (this.getNextCommand() === ALL_COMMANDS.START_RECORDING) {
                await this.executeNextCommand();
                this.#canExecuteNextCommand = true;
            }

            this.#isPlayingSoundEffect = true;
        }, previousElementOffset * 1000);

        node.scheduleStart(this.#soundSettings.sfx.fadeOutOnSkipDuration, previousElementOffset);

        node.volume = this.#soundEffectAudiosVolume;
    };

    schedulePreProducedAudio = index => {
        const nodeKey = Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX))
            .find(nodeKey => nodeKey === `${PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX}${index}`);

        if (!nodeKey) {
            return;
        }

        const node = this.#nodes[nodeKey];

        const previousElementOffset = this.getPreviousElementNode().getDuration();

        Debug.debug('RecordingAlgorithm', `Scheduling play for content bit in ${previousElementOffset} seconds.`);

        setTimeout(async () => {
            if (this.getNextCommand() === ALL_COMMANDS.START_RECORDING) {
                await this.executeNextCommand();
                this.#canExecuteNextCommand = true;
            }

            this.#isPlayingPreProducedAudio = true;

            this.#timeoutsAndIntervals.onPreProducedAudioEndTimeout = setTimeout(() => {
                Debug.debug('RecordingAlgorithm', `Stopping pre produced audio.`);

                this.#onPreProducedAudioEnd(nodeKey);
            }, this.#nodes[nodeKey].duration * 1000);
        }, previousElementOffset * 1000);

        node.scheduleStart(previousElementOffset);

        node.volume = this.#preProducedAudiosVolume;
    };

    scheduleBed = async index => {
        const nodeKey = Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(MUSIC_BED_AUDIO_FILE_NAME_PREFIX))
            .find(nodeKey => nodeKey === `${MUSIC_BED_AUDIO_FILE_NAME_PREFIX}${index}`);

        if (!nodeKey) {
            return;
        }

        // Bumper is playing, fade it out
        const fadeOutDuration = this.#soundSettings.bed.previousElementFadeOutDuration;
        // const toVolume = FADE_OUT_VARS.FADE_OUT_END_VOLUME;

        const node = this.#nodes[nodeKey];

        const previousElementOffset = this.getPreviousElementNode() ? this.getPreviousElementNode().getDuration() : 0;

        Debug.debug('RecordingAlgorithm', `Scheduling play for music bed in ${previousElementOffset} seconds.`);

        if (this.#nodes.previousElementNode) {
            Debug.debug('RecordingAlgorithm', `Scheduling fade out of previous element over ${fadeOutDuration} seconds.`);
        }

        setTimeout(async () => {
            if (this.getNextCommand() === ALL_COMMANDS.START_RECORDING) {
                await this.executeNextCommand();
                this.#canExecuteNextCommand = true;
            }

            this.#isPlayingMusicBed = true;

            if (this.#nodes.previousElementNode) {
                this.#nodes.previousElementNode.volumeTransition(fadeOutDuration, FADE_OUT_VARS.FADE_OUT_END_VOLUME);
            }
        }, previousElementOffset * 1000);

        node.scheduleStart(previousElementOffset);

        node.volume = this.#musicBedAudiosVolume;

        Debug.debug('RecordingAlgorithm', `Scheduling music bed end in ${node.duration + previousElementOffset} seconds.`);

        this.#timeoutsAndIntervals.onMusicBedEndTimeout = setTimeout(() => {
            Debug.debug('RecordingAlgorithm', `Stopping music bed.`);

            this.#isPlayingMusicBed = false;

            node.stop();

            this.notify([SUBSCRIPTION_TYPES.MUSIC_BED_DATA]);
        }, node.duration * 1000 + previousElementOffset * 1000);

        setTimeout(() => {
            if (this.#nodes.previousElementNode && this.#nodes.previousElementNode.isPlaying) {
                Debug.debug('RecordingAlgorithm', `Scheduling previous element fade in in ${node.duration - this.#soundSettings.bed.previousElementFadeInDuration} seconds.`);

                this.#timeoutsAndIntervals.continuePlayingPreviousElementTimeout = setTimeout(() => {
                    const duration = this.#soundSettings.bed.previousElementFadeInDuration;
                    const toVolume = this.#soundSettings.previousElement.duckVolume;

                    Debug.debug('RecordingAlgorithm', `Previous element still playing, fading it back in to volume ${toVolume} over ${duration} seconds.`);

                    this.#nodes.previousElementNode.volumeTransition(duration, toVolume);
                }, (node.duration - this.#soundSettings.bed.previousElementFadeInDuration) * 1000);
            }
        }, 0);
    };

    async startTaskAndScheduleSfx(index) {
        if (this.getNextCommand() === ALL_COMMANDS.PLAY_PREVIOUS_ELEMENT) {
            await this.executeNextCommand();
            this.#canExecuteNextCommand = false;
        }

        await this.scheduleSfx(index);
    }

    async startTaskAndSchedulePreProducedAudio(index) {
        if (this.getNextCommand() === ALL_COMMANDS.PLAY_PREVIOUS_ELEMENT) {
            await this.executeNextCommand();
            this.#canExecuteNextCommand = false;
        }

        await this.schedulePreProducedAudio(index);
    }

    async startTaskAndScheduleBed(index) {
        if (this.getNextCommand() === ALL_COMMANDS.PLAY_PREVIOUS_ELEMENT) {
            await this.executeNextCommand();
            this.#canExecuteNextCommand = false;
        }

        await this.scheduleBed(index);
    }

    playSoundEffect(index) {
        const nodeKey = Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX))
            .find(nodeKey => nodeKey === `${SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX}${index}`);

        if (!nodeKey) {
            return;
        }

        const node = this.#nodes[nodeKey];

        // The node is fading out, prevent any actions until it's done.
        if (node.isStopping) {
            Debug.debug('RecordingAlgorithm', `Node is being stopped, can not execute any actions before the node is fully stopped.`);

            return;
        }

        // If it's currently playing, fade out and stop
        if (node.isPlaying) {
            Debug.debug('RecordingAlgorithm', `Fading out sound effect over ${this.#soundSettings.sfx.fadeOutOnSkipDuration} seconds.`);

            node.fadeOut(this.#soundSettings.sfx.fadeOutOnSkipDuration, () => {
                const currentlyPlayingSoundEffect = Object.keys(this.#nodes)
                    .filter(nodeKey => nodeKey.includes(SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX))
                    .map(nodeKey => this.#nodes[nodeKey])
                    .find((node, i) => node.isPlaying && i !== index);

                // If there is some other sound effect playing, don't switch the flag to false.
                if (!currentlyPlayingSoundEffect) {
                    this.#isPlayingSoundEffect = false;

                    this.notify([
                        SUBSCRIPTION_TYPES.SOUND_EFFECT_DATA,
                    ]);
                }
            });

            return;
        }

        if (!this.#canPlaySoundEffect) {
            Debug.debug('RecordingAlgorithm', `Playing of sound effects is forbidden at the moment.`);

            return;
        }

        // If it's not playing, but it was played before, reconnect the node (we can't start more than once)
        if (node.startedAt.length) {
            Debug.debug('RecordingAlgorithm', `Reconnecting sound effect node.`);

            node.reconnect(this.#soundEffectAudiosVolume);
        }

        Debug.debug('RecordingAlgorithm', `Playing sound effect.`);

        node.volume = this.#soundEffectAudiosVolume;

        this.#isPlayingSoundEffect = true;

        node.play(this.#soundSettings.sfx.fadeOutOnSkipDuration);

        Debug.debug('RecordingAlgorithm', `Adding total skipped duration of ${this.#totalSkippedDuration} seconds as offset to sound effect node.`);

        // Set skipped duration so it's added into the startAt of this node
        node.addSkippedDuration(this.#totalSkippedDuration);
    }

    playPreProducedAudio(index) {
        const nodeKey = Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX))
            .find(nodeKey => nodeKey === `${PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX}${index}`);

        if (!nodeKey) {
            return;
        }

        const node = this.#nodes[nodeKey];

        // The node is fading out, prevent any actions until it's done.
        if (node.isStopping) {
            Debug.debug('RecordingAlgorithm', `Node is being stopped, can not execute any actions before the node is fully stopped.`);

            return;
        }

        // If it's currently playing, reject the click
        if (node.isPlaying) {
            return;
        }

        const playingPreProducedNode = Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX))
            .find(nodeKey => this.#nodes[nodeKey].isPlaying);

        if (playingPreProducedNode) {
            return;
        }

        if (!this.#canPlayPreProducedAudio) {
            Debug.debug('RecordingAlgorithm', `Playing of content bits is forbidden at the moment.`);

            return;
        }

        // If it's not playing, but it was played before, reconnect the node (we can't start more than once)
        if (node.startedAt.length) {
            Debug.debug('RecordingAlgorithm', `Reconnecting content bits node.`);

            node.reconnect(this.#preProducedAudiosVolume);
        }

        Debug.debug('RecordingAlgorithm', `Playing content bits.`);

        node.volume = this.#preProducedAudiosVolume;

        this.#isPlayingPreProducedAudio = true;

        node.play();

        Debug.debug('RecordingAlgorithm', `Adding total skipped duration of ${this.#totalSkippedDuration} seconds as offset to content bit node.`);

        // Set skipped duration, so it's added into the startAt of this node
        node.addSkippedDuration(this.#totalSkippedDuration);

        this.#timeoutsAndIntervals.onPreProducedAudioEndTimeout = setTimeout(() => {
            Debug.debug('RecordingAlgorithm', `Stopping content bit audio.`);

            this.#onPreProducedAudioEnd(nodeKey);
        }, (this.#nodes[nodeKey].duration - this.#nodes[nodeKey].offset) * 1000);
    }

    playMusicBed(index, resetVolumeCallback) {
        if (this.#timeoutsAndIntervals.onMusicBedEndTimeout) {
            clearTimeout(this.#timeoutsAndIntervals.onMusicBedEndTimeout);

            Debug.debug('RecordingAlgorithm', `Clearing music bed end timeout.`);
        }

        if (this.#timeoutsAndIntervals.continuePlayingPreviousElementTimeout) {
            clearTimeout(this.#timeoutsAndIntervals.continuePlayingPreviousElementTimeout);

            Debug.debug('RecordingAlgorithm', `Clearing previous element fade in timeout.`);
        }

        const currentlyPlayingMusicBed = Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(MUSIC_BED_AUDIO_FILE_NAME_PREFIX))
            .map(nodeKey => this.#nodes[nodeKey])
            .find(node => node.isPlaying);

        const nodeKey = Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(MUSIC_BED_AUDIO_FILE_NAME_PREFIX))
            .find(nodeKey => nodeKey === `${MUSIC_BED_AUDIO_FILE_NAME_PREFIX}${index}`);

        if (!nodeKey) {
            return;
        }

        const clickedNode = this.#nodes[nodeKey];

        if (clickedNode.isPlaying) {
            const duration = this.#soundSettings.bed.fadeOutOnSkipDuration;
            const toVolume = FADE_OUT_VARS.FADE_OUT_END_VOLUME;

            Debug.debug('RecordingAlgorithm', `Fading out music bed over ${duration} seconds.`);

            clickedNode.volumeTransition(duration, toVolume, true);

            if (this.#nodes.previousElementNode) {
                this.#nodes.previousElementNode.gainNode.gain.cancelAndHoldAtTime(this.#audioContext.currentTime);

                const duration = this.#soundSettings.bed.previousElementFadeInDuration;
                const toVolume = this.#soundSettings.previousElement.duckVolume;

                Debug.debug('RecordingAlgorithm', `Fading in previous element to ${toVolume} over ${duration} seconds.`);

                this.#nodes.previousElementNode.volumeTransition(duration, toVolume);
            }

            return;
        } else if (currentlyPlayingMusicBed) {
            const duration = this.#soundSettings.bed.previousElementFadeOutDuration;
            const toVolume = FADE_OUT_VARS.FADE_OUT_END_VOLUME;

            Debug.debug('RecordingAlgorithm', `Found playing music bed, fading it out over ${duration} seconds.`);

            currentlyPlayingMusicBed.volumeTransition(duration, toVolume, true);
        } else if (this.#nodes.previousElementNode && this.#nodes.previousElementNode.gainNode.gain) {
            if (!this.#canPlayMusicBed) {
                Debug.debug('RecordingAlgorithm', `Playing of music beds is forbidden at the moment.`);

                return;
            }

            // Bumper is playing, fade it out
            const duration = this.#soundSettings.bed.previousElementFadeOutDuration;
            const toVolume = FADE_OUT_VARS.FADE_OUT_END_VOLUME;

            Debug.debug('RecordingAlgorithm', `Previous element still playing, fading it out over ${duration} seconds.`);

            this.#nodes.previousElementNode.volumeTransition(duration, toVolume);
        }

        if (!this.#canPlayMusicBed) {
            Debug.debug('RecordingAlgorithm', `Playing of music beds is forbidden at the moment.`);

            return;
        }

        if (resetVolumeCallback && typeof resetVolumeCallback === 'function') {
            resetVolumeCallback();
        }

        // If it's not playing, but it was played before, reconnect the node (we can't start more than once)
        if (clickedNode.startedAt.length) {
            Debug.debug('RecordingAlgorithm', `Reconnecting music bed.`);

            clickedNode.reconnect(this.#musicBedAudiosVolume);
        }

        Debug.debug('RecordingAlgorithm', `Playing music bed.`);

        clickedNode.volume = this.#musicBedAudiosVolume;

        this.#isPlayingMusicBed = true;

        clickedNode.play();

        Debug.debug('RecordingAlgorithm', `Adding total skipped duration of ${this.#totalSkippedDuration} seconds as offset to music bed node.`);

        clickedNode.addSkippedDuration(this.#totalSkippedDuration);

        Debug.debug('RecordingAlgorithm', `Scheduling music bed end in ${clickedNode.duration} seconds.`);

        this.#timeoutsAndIntervals.onMusicBedEndTimeout = setTimeout(() => {
            Debug.debug('RecordingAlgorithm', `Stopping music bed.`);

            this.#isPlayingMusicBed = false;

            clickedNode.stop();

            this.notify([SUBSCRIPTION_TYPES.MUSIC_BED_DATA]);
        }, (clickedNode.duration - clickedNode.offset) * 1000);

        const previousElementFadeInDuration = this.#soundSettings.bed.previousElementFadeInDuration;

        if (this.#nodes.previousElementNode && this.#nodes.previousElementNode.isPlaying) {
            Debug.debug('RecordingAlgorithm', `Scheduling previous element fade in in ${clickedNode.duration - clickedNode.offset - previousElementFadeInDuration} seconds.`);

            this.#timeoutsAndIntervals.continuePlayingPreviousElementTimeout = setTimeout(() => {
                const toVolume = this.#soundSettings.previousElement.duckVolume;

                Debug.debug('RecordingAlgorithm', `Previous element still playing, fading it back in to volume ${toVolume} over ${previousElementFadeInDuration} seconds.`);

                this.#nodes.previousElementNode.volumeTransition(previousElementFadeInDuration, toVolume);
            }, (clickedNode.duration - clickedNode.offset - previousElementFadeInDuration) * 1000);
        }
    }

    /**
     * @param {DDSItem} audioObject
     * @param {string} key
     * @param {BaseAudioNode} node
     * @param {number} volume
     * @param {number} offset
     * @returns {Promise<void>}
     */
    async setupAudioNode(audioObject, key, node, volume = 1, offset = 0) {
        try {
            this.#nodes[key] = node;

            const blob = await IndexedDb.getAudioBlob(audioObject.id);

            await this.#nodes[key].init(blob, this.#audioContext, audioObject.id, this.#destination);

            this.#nodes[key].audioObject = audioObject;

            if (volume) {
                this.#nodes[key].volume = volume;
            }

            if (offset) {
                this.#nodes[key].offset = offset;
            }

            if (node instanceof PreviousElementAudioNode) {
                this.notify([SUBSCRIPTION_TYPES.PREVIOUS_ELEMENT_DATA]);
            } else if (node instanceof NextElementAudioNode) {
                this.notify([SUBSCRIPTION_TYPES.NEXT_ELEMENT_DATA]);
            } else if (node instanceof PreProducedAudioNode) {
                this.notify([SUBSCRIPTION_TYPES.PRE_PRODUCED_AUDIO_DATA]);
            } else if (node instanceof SoundEffectAudioNode) {
                this.notify([SUBSCRIPTION_TYPES.SOUND_EFFECT_DATA]);
            } else if (node instanceof MusicBedAudioNode) {
                this.notify([SUBSCRIPTION_TYPES.MUSIC_BED_DATA]);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error({error});
            toastInstance.error(`Failed to create the node for: "${audioObject.title}".`);
        }
    }

    /**
     * Resets everything so the user can start from the beginning.
     *
     * @returns {Promise<void>}
     */
    async reset() {
        if (this.#timeoutsAndIntervals.fadeInPreviousElement) {
            clearTimeout(this.#timeoutsAndIntervals.fadeInPreviousElement);
        }

        if (this.#nodes.recordingNode && this.#nodes.recordingNode.isRecording) {
            await this.#nodes.recordingNode.stop();
        }

        if (this.#audioContext && this.#audioContext.state && this.#audioContext.state !== 'closed') {
            await this.#audioContext.close();
        }

        if (this.#timeoutsAndIntervals.moderationDurationInterval) {
            clearInterval(this.#timeoutsAndIntervals.moderationDurationInterval);
        }

        if (this.#requestAnimationFrameCallback) {
            cancelAnimationFrame(this.#requestAnimationFrameCallback);
        }

        if (this.#timeoutsAndIntervals.algorithmEndInterval) {
            clearInterval(this.#timeoutsAndIntervals.algorithmEndInterval);
        }

        if (this.#timeoutsAndIntervals.onNextElementEndTimeout) {
            clearTimeout(this.#timeoutsAndIntervals.onNextElementEndTimeout);
        }

        if (this.#timeoutsAndIntervals.onPreviousElementEndTimeout) {
            clearTimeout(this.#timeoutsAndIntervals.onPreviousElementEndTimeout);
        }

        if (this.#timeoutsAndIntervals.continuePlayingPreviousElementTimeout) {
            clearTimeout(this.#timeoutsAndIntervals.continuePlayingPreviousElementTimeout);
        }

        if (this.#timeoutsAndIntervals.onMusicBedEndTimeout) {
            clearTimeout(this.#timeoutsAndIntervals.onMusicBedEndTimeout);
        }

        if (this.#timeoutsAndIntervals.fadeOutNextElementTimeout) {
            clearTimeout(this.#timeoutsAndIntervals.fadeOutNextElementTimeout);
        }

        if (this.#timeoutsAndIntervals.forbidSkipPreProducedAudioTimeout) {
            clearTimeout(this.#timeoutsAndIntervals.forbidSkipPreProducedAudioTimeout);
        }

        if (this.#timeoutsAndIntervals.onPreProducedAudioEndTimeout) {
            clearTimeout(this.#timeoutsAndIntervals.onPreProducedAudioEndTimeout);
        }

        if (this.#audioContext && this.#audioContext.state) {
            this.#audioContext = new window.AudioContext();
        }

        this.#setCurrentCommandIndex(-1);
        this.#currentRecordedStreamIndex = -1;
        this.#currentPreProducedAudioIndex = 0;

        this.#nodes = {
            previousElementNode: null,
            nextElementNode: null,
            recordingNode: null,
        };

        this.#moderationDuration = 0;
        this.#shouldCountPassedTimeOfModeration = false;

        this.#isDone = false;
        this.#isUploaded = false;

        this.#recordedStreams = [];

        this.#commands = [
            ALL_COMMANDS.INIT,
            ALL_COMMANDS.PLAY_PREVIOUS_ELEMENT,
        ];

        this.#isPlayingPreProducedAudio = false;
        this.#isPlayingSoundEffect = false;
        this.#isPlayingMusicBed = false;

        this.#canPlayPreProducedAudio = false;
        this.#canPlaySoundEffect = false;
        this.#canPlayMusicBed = false;

        this.#canExecuteNextCommand = true;

        this.#canPreviousElementBeStopped = true;

        this.#playbackDuration = 0;

        this.#algorithmStartedAt = 0;

        this.#totalSkippedDuration = 0;

        this.notify([
            SUBSCRIPTION_TYPES.PREVIOUS_ELEMENT_DATA,
            SUBSCRIPTION_TYPES.NEXT_ELEMENT_DATA,
            SUBSCRIPTION_TYPES.MASTER_BUTTON,
            SUBSCRIPTION_TYPES.DISABLE_RECORDING_CONTROLS,
            SUBSCRIPTION_TYPES.PRE_PRODUCED_AUDIO_DATA,
            SUBSCRIPTION_TYPES.SOUND_EFFECT_DATA,
            SUBSCRIPTION_TYPES.MUSIC_BED_DATA,
            SUBSCRIPTION_TYPES.MODERATION_DURATION,
            SUBSCRIPTION_TYPES.IS_RECORDING,
        ]);
    }

    /**
     * @return {PreviousElementAudioNode}
     */
    getPreviousElementNode() {
        return this.#nodes.previousElementNode;
    }

    /**
     * @return {NextElementAudioNode}
     */
    getNextElementNode() {
        return this.#nodes.nextElementNode;
    }

    /**
     * @return {RecordingNode}
     */
    getRecordingNode() {
        return this.#nodes.recordingNode;
    }

    /**
     * @return {Array<PreProducedAudioNode>}
     */
    getPreProducedAudioNodes() {
        return Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX))
            .map(nodeKey => this.#nodes[nodeKey]);
    }

    /**
     * @return {Array<SoundEffectAudioNode>}
     */
    getSoundEffectAudioNodes() {
        return Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX))
            .map(nodeKey => this.#nodes[nodeKey]);
    }

    /**
     * @return {Array<MusicBedAudioNode>}
     */
    getMusicBedAudioNodes() {
        return Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(MUSIC_BED_AUDIO_FILE_NAME_PREFIX))
            .map(nodeKey => this.#nodes[nodeKey]);
    }

    /**
     * @return {Array}
     */
    getRecordedStreams() {
        return this.#recordedStreams;
    }

    /**
     * @return {string|Array<string>}
     */
    getNextCommand() {
        const command = this.#commands[this.#currentCommandIndex + 1];

        if (Array.isArray(command)) {
            return command[command.length - 1];
        }

        return command;
    }

    /**
     * Returns the length of the moderation (total duration of all recordings).
     *
     * @returns {number}
     */
    getModerationDuration() {
        return this.#moderationDuration;
    }

    /**
     * Returns some metadata about duration, time left and index of currently playing elements.
     *
     * @returns {{duration: number, elementIndex: number, timeLeft: number}|{}}
     */
    getCurrentlyPlayingAudioData(fileNamePrefix) {
        return Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(fileNamePrefix) && this.#nodes[nodeKey].isPlaying)
            .map(nodeKey => {
                const node = this.#nodes[nodeKey];

                return {
                    elementId: node.audioObject.id,
                    passedTime: Math.round((node.getPassedTime() + Number.EPSILON) * 1000) / 1000,
                    duration: node.duration - node.audioObject.cueIn / 1000,
                };
            });
    }

    /**
     * Returns some metadata about duration, time left and index of currently playing elements.
     *
     * @returns {{duration: number, elementIndex: number, timeLeft: number}|{}}
     */
    getPlayedAudios(fileNamePrefix) {
        return Object.keys(this.#nodes)
            .filter(nodeKey => {
                const node = this.#nodes[nodeKey];

                return nodeKey.includes(fileNamePrefix) && node && node.didEnd();
            })
            .map(nodeKey => {
                const node = this.#nodes[nodeKey];

                return node.audioObject.id;
            });
    }

    /**
     * Used to disable master button in some cases.
     *
     * @returns {boolean}
     */
    getCanExecuteNextCommand() {
        return this.#canExecuteNextCommand;
    }

    /**
     * @param {boolean} canExecuteNextCommand
     */
    setCanExecuteNextCommand(canExecuteNextCommand) {
        this.#canExecuteNextCommand = canExecuteNextCommand;

        this.notify([
            SUBSCRIPTION_TYPES.MASTER_BUTTON,
        ]);
    }

    resumeContext() {
        this.#audioContext.resume().then();
    }

    generateAlgorithmCommands() {
        // Get only PreProducedAudioNodes
        const preProducedAudioNodes = Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX))
            .map(nodeKey => this.#nodes[nodeKey]);

        const hasPreviousElementNode = !!this.#nodes.previousElementNode;
        const hasNextElementNode = !!this.#nodes.nextElementNode;
        let contentTypeOfPreviousElement = null;
        let elementTypeOfPreviousElement = null;

        if (hasPreviousElementNode) {
            contentTypeOfPreviousElement = this.#nodes.previousElementNode.audioObject.contentType;
            elementTypeOfPreviousElement = this.#nodes.previousElementNode.audioObject.objectElementType;
        }

        // Now it's safe to generate the algorithm commands.
        this.#commands = generateAlgorithmCommands(
            preProducedAudioNodes,
            hasPreviousElementNode,
            hasNextElementNode,
            contentTypeOfPreviousElement,
            elementTypeOfPreviousElement,
        );
    }

    /**
     * @param fileNamePrefix
     * @param volume
     */
    setElementsVolume(fileNamePrefix, volume) {
        Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(fileNamePrefix))
            .forEach(nodeKey => {
                const node = this.#nodes[nodeKey];

                if (node.isPlaying) {
                    node.volume = volume;
                }
            });
    }

    /**
     * @returns {SoundSettings}
     */
    getSoundSettings() {
        return this.#soundSettings;
    }

    /**
     * @param {SoundSettings} soundSettings
     */
    setSoundSettings(soundSettings) {
        this.#soundSettings = soundSettings;
    }

    /**
     * @returns {SoundSettings}
     */
    get soundSettings() {
        return this.#soundSettings;
    }

    /**
     * @returns {number}
     */
    get preProducedAudiosVolume() {
        return this.#preProducedAudiosVolume;
    }

    /**
     * @type {number} preProducedAudiosVolume
     */
    set preProducedAudiosVolume(preProducedAudiosVolume) {
        this.#preProducedAudiosVolume = preProducedAudiosVolume;
    }

    /**
     * @returns {number}
     */
    get soundEffectAudiosVolume() {
        return this.#soundEffectAudiosVolume;
    }

    /**
     * @type {number} soundEffectAudiosVolume
     */
    set soundEffectAudiosVolume(soundEffectAudiosVolume) {
        this.#soundEffectAudiosVolume = soundEffectAudiosVolume;
    }

    /**
     * @returns {number}
     */
    get musicBedAudiosVolume() {
        return this.#musicBedAudiosVolume;
    }

    /**
     * @type {number} musicBedAudiosVolume
     */
    set musicBedAudiosVolume(musicBedAudiosVolume) {
        this.#musicBedAudiosVolume = musicBedAudiosVolume;

        // Find playing music bed and adjust volume
        const currentlyPlayingMusicBed = Object.keys(this.#nodes)
            .filter(nodeKey => nodeKey.includes(MUSIC_BED_AUDIO_FILE_NAME_PREFIX))
            .map(nodeKey => this.#nodes[nodeKey])
            .find(node => node.isPlaying);

        if (currentlyPlayingMusicBed) {
            currentlyPlayingMusicBed.volume = musicBedAudiosVolume;
        }
    }

    /**
     * @returns {AudioContext}
     */
    get audioContext() {
        return this.#audioContext;
    }

    /**
     * @returns {number|null}
     */
    get algorithmStartedAt() {
        return this.#algorithmStartedAt;
    }

    /**
     * @returns {boolean}
     */
    get isDone() {
        return this.#isDone;
    }

    /**
     * @param {boolean} isUploaded
     */
    set isUploaded(isUploaded) {
        this.#isUploaded = isUploaded;

        this.notify([SUBSCRIPTION_TYPES.DISABLE_RECORDING_CONTROLS]);
    }

    /**
     * @param {boolean} canBePlayed
     */
    set canBePlayed(canBePlayed) {
        this.#canBePlayed = canBePlayed;

        this.notify([SUBSCRIPTION_TYPES.DISABLE_RECORDING_CONTROLS]);
    }

    isInProgress = () => {
        if (!this.#audioContext) {
            return false;
        }

        return this.#audioContext && this.audioContext.state === 'running' && this.#currentCommandIndex !== -1;
    };

    isReadyToStart = () => {
        if (this.getNextCommand() === ALL_COMMANDS.PLAY_PREVIOUS_ELEMENT) {
            return true;
        }

        return this.getNextCommand() === ALL_COMMANDS.START_RECORDING;
    };

    #getPreviousElementProgress = () => {
        const length = this.getPreviousElementNode().getDuration();
        const playingPosition = this.getPreviousElementNode().getPassedTime();

        if (!length && !playingPosition) {
            return 0;
        }

        const progress = (playingPosition * 100) / length;

        if (progress > 100) {
            return 100;
        } else if (progress < 0) {
            return 0;
        }

        return progress;
    };

    #getNextElementProgress = () => {
        const length = this.getNextElementNode().getDuration();
        const playingPosition = this.getNextElementNode().getPassedTime();

        if (!length && !playingPosition) {
            return 0;
        }

        const progress = (playingPosition * 100) / length;

        if (progress > 100) {
            return 100;
        } else if (progress < 0) {
            return 0;
        }

        return progress;
    };

    /**
     * @param subscriptionType
     * @returns {{
     *      length: number,
     *      playingPosition: number
     *  } | {} | {
     *      isRecordingDone: boolean
     *  } | {
     *      playedElements: ({
     *          duration: number,
     *          elementIndex: number,
     *          timeLeft: number
     *      } | {}),
     *      playingElements: ({
     *          duration: number,
     *          elementIndex: number,
     *          timeLeft: number
     *          } | {})
     *  } | {
     *      areControlsDisabled: boolean,
     *      isEditButtonDisabled: boolean,
     *      isResetButtonDisabled: boolean
     *  } | {
     *      moderationDuration: number
     *  } | {
     *      color: string,
     *      subtitle: string,
     *      icon: *,
     *      isDisabled: boolean,
     *      title: (string|*)
     *  }}
     */
    getSubscriptionData = subscriptionType => {
        switch (subscriptionType) {
            case SUBSCRIPTION_TYPES.PREVIOUS_ELEMENT_DATA: {
                if (!this.getPreviousElementNode()) {
                    return {
                        progress: 0,
                        length: 0,
                        countdownDirection: 'positive',
                        playingPosition: 0,
                        hasNode: false,
                    };
                }

                const node = this.getPreviousElementNode();

                return {
                    length: node.getDuration(),
                    playingPosition: node.getPassedTime(),
                    countdownDirection: node.getCountdownDirection(),
                    progress: this.#getPreviousElementProgress(),
                    hasNode: true,
                };
            }

            case SUBSCRIPTION_TYPES.NEXT_ELEMENT_DATA: {
                if (!this.getNextElementNode()) {
                    return {
                        progress: 0,
                        length: 0,
                        countdownDirection: 'positive',
                        playingPosition: 0,
                        hasNode: false,
                    };
                }

                const node = this.getNextElementNode();

                return {
                    length: node.getDuration(),
                    playingPosition: node.getPassedTime(),
                    countdownDirection: node.getCountdownDirection(),
                    progress: this.#getNextElementProgress(),
                    hasNode: true,
                };
            }

            case SUBSCRIPTION_TYPES.MASTER_BUTTON: {
                const nextCommand = this.getNextCommand();
                const {colorName, hex} = masterButtonColorMap[nextCommand];

                let isDisabled = !this.getCanExecuteNextCommand();

                if (this.getNextCommand() === ALL_COMMANDS.UPLOAD) {
                    isDisabled = !this.getCanExecuteNextCommand() || !this.#isDone;
                }

                return {
                    title: masterButtonTitleMap[nextCommand] ?? 'Record',
                    subtitle: masterButtonSubtitleMap[nextCommand] ?? '',
                    icon: masterButtonIconMap[nextCommand],
                    imageSource: masterButtonImageSourceMap[nextCommand],
                    color: colorName ?? 'stone',
                    backgroundColor: hex ?? '#747f88',
                    isDisabled,
                };
            }

            case SUBSCRIPTION_TYPES.MODERATION_DURATION: {
                return {
                    moderationDuration: this.#moderationDuration,
                };
            }

            case SUBSCRIPTION_TYPES.PRE_PRODUCED_AUDIO_DATA: {
                return {
                    elements: Object.keys(this.#nodes)
                        .filter(nodeKey => {
                            return nodeKey.includes(PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX)
                                && !!this.#nodes[nodeKey].audioObject?.id;
                        })
                        .map(nodeKey => {
                            const node = this.#nodes[nodeKey];

                            return {
                                elementId: node.audioObject.id,
                                passedTime: Math.round((node.getPassedTime() + Number.EPSILON) * 1000) / 1000,
                                duration: node.duration - node.audioObject.cueIn / 1000,
                            };
                        }),
                    playingElements: this.getCurrentlyPlayingAudioData(PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX),
                    playedElements: this.getPlayedAudios(PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX),
                };
            }

            case SUBSCRIPTION_TYPES.SOUND_EFFECT_DATA: {
                return {
                    elements: Object.keys(this.#nodes)
                        .filter(nodeKey => {
                            return nodeKey.includes(SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX)
                            && !!this.#nodes[nodeKey].audioObject?.id;
                        })
                        .map(nodeKey => {
                            const node = this.#nodes[nodeKey];

                            return {
                                elementId: node.audioObject.id,
                                passedTime: Math.round((node.getPassedTime() + Number.EPSILON) * 1000) / 1000,
                                duration: node.duration - node.audioObject.cueIn / 1000,
                            };
                        }),
                    playingElements: this.getCurrentlyPlayingAudioData(SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX),
                    playedElements: this.getPlayedAudios(SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX),
                };
            }

            case SUBSCRIPTION_TYPES.MUSIC_BED_DATA: {
                return {
                    elements: Object.keys(this.#nodes)
                        .filter(nodeKey => {
                            return nodeKey.includes(MUSIC_BED_AUDIO_FILE_NAME_PREFIX)
                                && !!this.#nodes[nodeKey].audioObject?.id;
                        })
                        .map(nodeKey => {
                            const node = this.#nodes[nodeKey];

                            return {
                                elementId: node.audioObject.id,
                                passedTime: Math.round((node.getPassedTime() + Number.EPSILON) * 1000) / 1000,
                                duration: node.duration - node.audioObject.cueIn / 1000,
                            };
                        }),
                    playingElements: this.getCurrentlyPlayingAudioData(MUSIC_BED_AUDIO_FILE_NAME_PREFIX),
                    playedElements: this.getPlayedAudios(MUSIC_BED_AUDIO_FILE_NAME_PREFIX),
                };
            }

            case SUBSCRIPTION_TYPES.RECORDING_ALGORITHM_DONE: {
                return {
                    isRecordingDone: this.#isDone,
                };
            }

            case SUBSCRIPTION_TYPES.DISABLE_RECORDING_CONTROLS: {
                return {
                    areControlsDisabled: this.#currentCommandIndex !== -1 && !this.#canBePlayed,
                    isEditButtonDisabled: false,
                    isResetButtonDisabled: this.#currentCommandIndex === -1,
                };
            }

            case SUBSCRIPTION_TYPES.IS_RECORDING: {
                if (!this.#nodes.recordingNode) {
                    return {
                        isRecording: false,
                    };
                }

                return {
                    isRecording: this.#nodes.recordingNode.isRecording,
                };
            }

            default:
                return {};
        }
    };

    setReduxDispatch = reduxDispatch => {
        this.#reduxDispatch = reduxDispatch;
    };

    setAllowKeyboardEvents = areEventsAllowed => {
        this.#allowKeyboardEvents = areEventsAllowed;
    };
}

export default new RecordingAlgorithm();
